import React from 'react';


export const ImageWithFallback = ({ src, alt, fallbackSrc, width = 32, height = 32, title = undefined }) => {
  const errorHandler = (ev) => {
    ev.target.onerror = null; // Prevents infinite loop if fallback also fails
    ev.target.src = fallbackSrc;
  };

  return (
    <img
      className='rounded'
      src={src}
      alt={alt}
      title={title}
      width={width}
      height={height}
      onError={errorHandler}
    />
  );
};
