import React, { useState } from 'react';
import { Form, Row, Col, Container, Button, Modal, } from '@themesberg/react-bootstrap';
import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import { Routes } from '../routes';
import LoadingOverlay from '../components/LoadingOverlay';
import { getAxiosInstance, handleAxiosError } from '../utils/betterAxiosHelpers';
import toast from 'react-hot-toast';
import { genericErrorMessages } from '../utils/errorMessages';
import { GatewayGroup } from '../components/GatewayGroup';


const GATEWAYS_BASE_ROUTE = '/gateway-routes';

export const GatewayRoutes = () => {
  const [cookies] = useCookies(['token', 'user']);
  const [shouldLogin, setShouldLogin] = useState(false);

  const [isBusy, setIsBusy] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [groups, setGroups] = useState([]);

  const [gateway, setGateway] = useState('');
  const [processor, setProcessor] = useState('');
  const [transactionType, setTransactionTyep] = useState('');

  const [modalBody, setModalBody] = useState('');
  const [selectedRoute, setSelectedRoute] = useState(null);

  const axios = getAxiosInstance();

  const axiosErrorHandlers = {
    onForbidden: () => setShouldLogin(true),
    onClientError: () => toast.error('Données invalides. Veuillez vérifier votre saisie.'),
    onServerError: () => toast.error(genericErrorMessages.serverError),
    onRequestError: () => toast.error(genericErrorMessages.requestError),
    onError: () => toast.error(genericErrorMessages.networkError)
  };

  const onGatewayChange = (ev) => setGateway(ev.target.value.trim());
  const onProcessorChange = (ev) => setProcessor(ev.target.value.trim());
  const onTransactionTypeChange = (ev) => setTransactionTyep(ev.target.value);

  const fetchGatewayRoutes = () => {
    return axios.get(GATEWAYS_BASE_ROUTE, {
      headers: {
        AuthenticationToken: cookies.token
      },
      params: { gateway, processor, transactionType, }
    });
  };

  const onFormSubmit = async (ev) => {
    ev.preventDefault();
    setSelectedRoute(null);

    setIsBusy(true);
    try {
      const { data } = await fetchGatewayRoutes();
      const gb = Object.groupBy(data, (route) => route.gateway);
      const groups = Object.keys(gb)
        .map(gateway => {
          return {
            gateway,
            routes: gb[gateway]
          };
        });

      setGroups(groups);
    } catch (error) {
      handleAxiosError(error, axiosErrorHandlers);
      setGroups([]);
    }
    setIsBusy(false);
  };

  const actOnRoute = async (route) => {
    const { id, enabled } = route;
    const action = enabled ? 'disable' : 'enable';
    const path = `${GATEWAYS_BASE_ROUTE}/${id}/${action}`;

    try {
      const { data: updatedRoute } = await axios.put(path, null, {
        headers: {
          AuthenticationToken: cookies.token,
        },
      });
      const { gateway } = updatedRoute;

      setGroups(previousGroups => {
        return previousGroups.map(group => {
          if (group.gateway !== gateway) {
            return group;
          }

          const { routes } = group;
          const newRoutes = routes.map(r => r.id === updatedRoute.id ? updatedRoute : r);

          return {
            gateway,
            routes: newRoutes
          };
        });
      });
    } catch (error) {
      toast.error(`Une erreur est survenue lors de l'exécution de l'opération.`)
    }
  };

  const onModalClose = async (confirm) => {
    setShowModal(false);
    if (!confirm || !selectedRoute) {
      return;
    }

    setIsBusy(true);
    await actOnRoute(selectedRoute);
    setSelectedRoute(null);
    setIsBusy(false);
  };

  const handleRouteSwitch = (route) => {
    if (!cookies.user?.canActOnGatewayRoute) {
      toast.error(`Vous n'avez pas les autorisations pour éffectuer cette action.`);
      return;
    }

    const action = route.enabled ? 'Désactiver' : 'Activer';
    const name = `${route.gateway}_${route.processor}`;

    setModalBody(`${action} la route ${name} ?`);
    setSelectedRoute(route);
    setShowModal(true);
  };

  if (!cookies.token || !cookies.user) {
    return (
      <Redirect to={Routes.Signin.path} />
    );
  }

  if (shouldLogin) {
    return (
      <Redirect to={Routes.Signin.path} />
    );
  }

  return (
    <Container style={{ pointerEvents: isBusy ? 'none' : 'auto' }}>
      <LoadingOverlay isActive={isBusy} />
      <>
        <div className='py-4'>
          Veuillez remplir les champs d'intérêt et appuyer sur le bouton <strong>Filtrer</strong> pour afficher les routes correspondantes.
        </div>

        <Form onSubmit={onFormSubmit}>
          <Row>
            <Col>
              <Form.Label>Gateway</Form.Label>
              <Form.Control
                type='text'
                onChange={onGatewayChange}
              />
            </Col>

            <Col>
              <Form.Label>Processor</Form.Label>
              <Form.Control
                type='text'
                onChange={onProcessorChange}
              />
            </Col>

            <Col>
              <Form.Label>Type</Form.Label>
              <Form.Select onChange={onTransactionTypeChange}>
                <option value=''>Tous</option>
                <option value='transfer'>Tranfert</option>
                <option value='payment'>Paiement</option>
              </Form.Select>
            </Col>
          </Row>

          <Row>
            <Col className="py-3">
              <Button type='submit' className='py-1'>Filtrer</Button>
            </Col>
          </Row>
        </Form>

        <For each='group' of={groups}>
          <Row key={group.gateway} className='mb-2'>
            <Col>
              <GatewayGroup
                gateway={group.gateway}
                gatewayRoutes={group.routes}
                onRouteSwitch={handleRouteSwitch}
              />
            </Col>
          </Row>
        </For>

        <Modal
          show={showModal}
          onHide={() => onModalClose(false)}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header className='bg-primary text-white' closeButton closeVariant='white'>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalBody}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={() => onModalClose(false)}>
              Annuler
            </Button>
            <Button variant={selectedRoute?.enabled ? 'danger' : 'success'} onClick={() => onModalClose(true)}>
              Confirmer
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </Container>
  );
};
