export const Routes = {
  DashboardOverview: { path: "/" },
  Transactions: { path: "/transactions" },
  Settings: { path: "/settings" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  Signin: { path: "/signin" },
  VerifyAuth: { path: "/verify-auth" },
  Signup: { path: "/examples/sign-up" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },

  RefreshStatus: { path: "/refresh" },
  Statistique: { path: "/statistique" },
  CheckStatus: { path: "/check" },
  Solde: { path: "/solde" },
  Transfer: { path: "/transfer" },
  TransferList: { path: "/transfer-list" },
  MerchantTransferBalance: { path: "/merchant-balance" },
  MoovBalance: { path: "/moov-balance" },
  DailyReport: { path: "/daily-report" },
  MerchantCollectionBalance: { path: "/merchant-collection" },
  HistoryMerchantBalanceTransfer: { path: "/history-merchant-balance-transfer" },
  HistoryProviderBalanceTransfer: { path: "/history-provider-balance-transfer" },
  HistoryMerchantBalanceCollection: { path: "/history-merchant-balance-collection" },
  BalanceSetting: { path: "/balance-setting" },
  GlobalReport: { path: "/global-report" },
  SmsContentOrangeCashIn: { path: "/sms-content/orange-cash-in" },
  SmsOrangeCashInCandidate: { path: "/sms-orange/cash-in/candidate" },
  TransferReporting: { path: "/transfer-reporting" },
  CollectionDailyReport: { path: "/collection/daily-report" },
  ProviderPaymentBalance: { path: "/provider-payment-balance" },
  HistoryProviderBalanceCollection: { path: "/history-provider-balance-collection" },
  PaymentReporting: { path: "/payment-reporting" },
  TransferForceStatus: { path: "/transfer-force-status" },
  PaymentLocalStats: { path: "/payment-local-stats" },
  TransferLocalStats: { path: "/transfer-local-stats" },
  OrangeReportTransfer: { path: "/orange-report-transfer" },
  OrangeReportTransferCandidate: { path: "/orange-report-transfer/candidate" },
  OrangeReportPayment: { path: "/orange-report-payment" },
  OrangeReportPaymentCandidate: { path: "/orange-report-payment/candidate" },
  MarkOrangePaymentLikeRegularised: { path: "/orange-payment/regularised" },
  TransfersLocalDailyReport: { path: "/local/transfer/daily-report" },
  CollectionsLocalDailyReport: { path: "/local/collection/daily-report" },
  LocalPaymentReporting: { path: "/local/payment-reporting" },
  LocalTransferReporting: { path: "/local/transfer-reporting" },
  MarkOrangeTransferLikeRegularised: { path: "/orange-transfer/regularised" },
  ChooseOperator: { path: "/payment-choose-operator" },
  ChooseOperatorTransfer: { path: "/transfer-choose-operator" },
  WaveReportPayment: { path: "/wave-report-payment" },
  MarkWavePaymentLikeRegularised: { path: "/wave-payment/regularised" },
  WaveReportTransfer: { path: "/wave-report-transfer" },
  MarkWaveTransferLikeRegularised: { path: "/wave-transfer/regularised" },
  MoovReportPayment: { path: "/moov-report-payment" },
  MarkMoovPaymentLikeRegularised: { path: "/moov-payment/regularised" },
  MoovReportTransfer: { path: "/moov-report-transfer" },
  MarkMoovTransferLikeRegularised: { path: "/moov-transfer/regularised" },
  IntouchReportTransfer: { path: "/intouch-report-transfer" },
  MarkIntouchTransferLikeRegularised: { path: "/intouch-transfer/regularised" },
  IntouchReportPayment: { path: "/intouch-report-payment" },
  MarkIntouchPaymentLikeRegularised: { path: "/intouch-payment/regularised" },
  MtnReportTransfer: { path: "/mtn-report-transfer" },
  MarkMtnTransferLikeRegularised: { path: "/mtn-transfer/regularised" },
  MtnReportPayment: { path: "/mtn-report-payment" },
  MarkMtnPaymentLikeRegularised: { path: "/mtn-payment/regularised" },
  OrangeSnPayment: { path: "/orange-sn-payment" },
  MarkOrangeSnPaymentLikeRegularised: { path: "/orange-sn-payment/regularised" },
  FreeReportPayment: { path: "/free-report-payment" },
  MarkFreePaymentLikeRegularised: { path: "/free-payment/regularised" },
  FreeReportTransfer: { path: "/free-report-transfer" },
  MarkFreeTransferLikeRegularised: { path: "/free-transfer/regularised" },
  MerchantListFees: { path: "/merchant-list-fees" },
  RightsManagement: { path: "/rights-management" },
  MerchantListFeesLive: { path: "/merchant-list-fees-live" },
  FedapayReportTransfer: { path: "/fedapay-report-transfer" },
  MarkFedapayTransferLikeRegularised: { path: "/fedapay-transfer/regularised" },
  PaymentForceStatus: { path: "/payment-force-status" },
  FedapayReportPayment: { path: "/fedapay-report-payment" },
  MarkFedapayPaymentLikeRegularised: { path: "/fedapay-payment/regularised" },
  RetourDeFonds: { path: "/retour-de-fonds" },
  RetourDeFondsLogs: { path: "/retour-de-fonds/logs" },

  // Gateway Routes
  GatewayRoutes: { path: "/gateway-routes" },

  // Jobs
  JobList: { path: "/jobs" },
  BulkCheckStatusForm: { path: "/bulk-check-status-form" },
  TransactionBlacklistPage: { path: "/transaction-blacklist" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" }
};
