import React from 'react';
import {
  Col,
  Row,
  Card,
  Form,
  OverlayTrigger,
  Popover,
  PopoverContent,
} from '@themesberg/react-bootstrap';
import { ImageWithFallback } from "./ImageWithFallback";
import { africanCountryCodes } from '../utils/countryCodes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from '@fortawesome/free-regular-svg-icons';


export const GatewayRouteCard = ({ gatewayRoute, onSwitch, }) => {
  const { id, processor, transactionType } = gatewayRoute;
  const [, country, provider] = processor.split('_');
  const countryCode = country.toUpperCase();
  const countryName = africanCountryCodes[countryCode];

  const countryPopover = (
    <Popover id={`${id}-country`}>
      <PopoverContent className='px-4 py-1 text-primary'>{countryName}</PopoverContent>
    </Popover>
  );

  const providerPopover = (
    <Popover id={`${id}-gateway`}>
      <PopoverContent className='px-4 py-1 text-primary'>{provider}</PopoverContent>
    </Popover>
  );

  return (
    <Card border='light' className='shadow-sm border-warning' style={{ flex: 1 }}>
      <Card.Body className='pb-1'>
        <Row className='d-flex justify-content-between'>
          <Col className='align-self-center text-start'>
            <OverlayTrigger placement='top' overlay={countryPopover}>
              <span>
                <ImageWithFallback
                  src={`https://hatscripts.github.io/circle-flags/flags/${country}.svg`}
                  alt={africanCountryCodes[countryCode]}
                  title={africanCountryCodes[countryCode]}
                  width={32}
                  height={32}
                />
              </span>
            </OverlayTrigger>
          </Col>

          <Col className='align-self-center text-end'>
            <OverlayTrigger placement='top' overlay={providerPopover}>
              <span>
                <ImageWithFallback
                  src={`/images/${provider}.png`}
                  fallbackSrc={`/images/provider.png`}
                  alt='Provider'
                  width={32}
                  height={32}
                />
              </span>
            </OverlayTrigger>
          </Col>
        </Row>

        <Row>
          <Col className='mt-3'>
            <h6 className='text-center'>
              <FontAwesomeIcon icon={faIdCard} size='lg' />
              <span className='ms-2'>{id}</span>
            </h6>
            <p className='text-center'>
              {processor}
            </p>
          </Col>
        </Row>
      </Card.Body>

      <Card.Footer className='p-2 text-end'>
        <Row className='d-flex justify-content-between'>
          <Col className='text-start'>{transactionType}</Col>
          <Col className='text-end'>
            <Form.Switch
              readOnly
              checked={gatewayRoute.enabled}
              onClick={() => onSwitch(gatewayRoute)}
            />
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};
