import React, { useState } from 'react';
import {
  Col,
  Row,
  Container,
  Form,
  Accordion,
} from '@themesberg/react-bootstrap';
import { GatewayRouteCard } from "./GatewayRouteCard";
import { ImageWithFallback } from "./ImageWithFallback";
import { africanCountryCodes } from '../utils/countryCodes';


const extractCountryCode = (route) => {
  return route.processor.split('_').at(1);
}

export const GatewayGroup = ({ gateway, gatewayRoutes, onRouteSwitch }) => {
  const gb = Object.groupBy(gatewayRoutes, extractCountryCode);
  const countryCodes = Object.keys(gb).sort();

  const [filter, setFilter] = useState('');

  const displayedRoutes = gatewayRoutes.filter(route => {
    return filter ? extractCountryCode(route) === filter : true;
  });

  return (
    <Accordion>
      <Accordion.Item eventKey={gateway}>
        <Accordion.Header>
          <ImageWithFallback
            src={`/images/${gateway}.png`}
            fallbackSrc={`/images/gateway.png`}
            alt='Gateway'
            width={32}
            height={32}
          />
          <span className='ms-2 fw-bold'>{gateway}</span>
        </Accordion.Header>

        <Accordion.Body>
          <Form.Select onChange={(ev) => setFilter(ev.target.value)}>
            <option value=''>Tous</option>
            <For each='code' of={countryCodes}>
              <option key={code} value={code}>{africanCountryCodes[code.toUpperCase()]}</option>
            </For>
          </Form.Select>

          <Container className='px-0 mt-4'>
            <Row>
              <For each='gatewayRoute' of={displayedRoutes}>
                <Col
                  key={gatewayRoute.id}
                  xs={12}
                  sm={6}
                  md={5}
                  lg={4}
                  className="mb-4 border-warning "
                >
                  <GatewayRouteCard
                    gatewayRoute={gatewayRoute}
                    onSwitch={() => onRouteSwitch(gatewayRoute)}
                  />
                </Col>
              </For>
            </Row>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
