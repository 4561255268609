export const africanCountryCodes = {
  'DZ': 'Algérie',
  'AO': 'Angola',
  'BJ': 'Bénin',
  'BN': 'Bénin (BN)', // FIXME: This is temporary hack before the core team corrects the wrong code assigned to Benin.
  'BW': 'Botswana',
  'BF': 'Burkina Faso',
  'BI': 'Burundi',
  'CV': 'Cap-Vert',
  'CM': 'Cameroun',
  'CF': 'République centrafricaine',
  'TD': 'Tchad',
  'KM': 'Comores',
  'CG': 'Congo',
  'CD': 'République démocratique du Congo',
  'CI': 'Côte d\'Ivoire',
  'DJ': 'Djibouti',
  'EG': 'Égypte',
  'GQ': 'Guinée équatoriale',
  'ER': 'Érythrée',
  'SZ': 'Eswatini',
  'ET': 'Éthiopie',
  'GA': 'Gabon',
  'GM': 'Gambie',
  'GH': 'Ghana',
  'GN': 'Guinée',
  'GW': 'Guinée-Bissau',
  'KE': 'Kenya',
  'LS': 'Lesotho',
  'LR': 'Libéria',
  'LY': 'Libye',
  'MG': 'Madagascar',
  'MW': 'Malawi',
  'ML': 'Mali',
  'MR': 'Mauritanie',
  'MU': 'Maurice',
  'MA': 'Maroc',
  'MZ': 'Mozambique',
  'NA': 'Namibie',
  'NE': 'Niger',
  'NG': 'Nigeria',
  'RW': 'Rwanda',
  'ST': 'Sao Tomé-et-Principe',
  'SN': 'Sénégal',
  'SC': 'Seychelles',
  'SL': 'Sierra Leone',
  'SO': 'Somalie',
  'ZA': 'Afrique du Sud',
  'SS': 'Soudan du Sud',
  'SD': 'Soudan',
  'TZ': 'Tanzanie',
  'TG': 'Togo',
  'TN': 'Tunisie',
  'UG': 'Ouganda',
  'ZM': 'Zambie',
  'ZW': 'Zimbabwe'
};
